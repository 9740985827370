<template>
  <div class="login_box">
    <div class="top_logo">
      <img src="../../assets/login/logo.png">
    </div>
    <div class="operation_login">
      <van-form @submit="onSubmit">
        <van-row>
          <van-col span="18">
            <img src="../../assets/login/login_head.png">
            <van-field v-model="phone" name="手机号" label="" placeholder="请输入手机号码" :rules="[{ required: true, message: '请填写正确的手机号' }]" />
          </van-col>
          <van-col span="6"></van-col>
        </van-row>
        <van-row>
          <van-col span="18">
            <img src="../../assets/login/Img_Code.png">
            <van-field v-model="isValidNum" name="验证码" label="" placeholder="请输入图片验证码" :rules="[{ required: true, message: '图片验证码错误' }]" />
          </van-col>
          <van-col span="6"> <span>验证码</span></van-col>
        </van-row>
        <van-row>
          <van-col span="14">
            <img src="../../assets/login/login_lock.png">
            <van-field v-model="validNum" name="获取验证码" label="" placeholder="请输入验证码" :rules="[{ required: true, message: '验证码错误' }]" />
          </van-col>
          <van-col span="10">
            <van-button block type="info">获取验证码</van-button>
          </van-col>
        </van-row>
        <van-row>
          <van-checkbox v-model="checked"><span @click="jumpAgreement">同意 《水费易生活缴费服务协议》</span></van-checkbox>
        </van-row>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checked: false,
    }
  },
  methods: {
    jumpAgreement () {
      this.$router.push('/agreement')
    }
  }
}
</script>
 
<style lang = "less" scoped>
.login_box {
  .top_logo {
    width: 100%;
    height: 190px;
    text-align: center;
    background: url('../../assets/login/login_backImg.jpg') no-repeat;
    background-size: 100% 100%;
    img {
      margin-top: 40px;
      width: 190px;
      height: 65px;
    }
  }
  .operation_login {
    padding: 60px 40px 0 40px;
    /deep/ .van-form {
      .van-row {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .van-col {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .van-checkbox {
          padding: 10px 0 40px 0;
        }
      }
    }
  }
}
</style>